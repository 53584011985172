.secc2_AboutUs{
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-top: 3%;
    padding-bottom: 50px;
}

.formulario_secc2AU{
    background-color: #143B36;
    padding:30px;
    height: auto;
    margin-right: 40px;
    color: white;
    display: flex;
    flex-direction: column;
}

.formulario_secc2AU h2{
    margin-bottom: 0;
}

.formulario_secc2AU input{
    margin-bottom: 5px;
}

.input_formularioAU1{
    background-color: #143B36;
    border: 0.7px solid white;
    border-radius: 10px;
    color: white;
    padding: 10px;
    width: 95%;
    font-size: 1em;
    font-weight: 70;
}

.input_mensajeAU1{
    height: 70px;
}

.boton_formularioAU{
    font-size: 1em;
    height: 4em;
    width: 30%;
    color: white;
    background-color: #00AFAA;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.informacionsecc2AU h2{
    margin-top: 0;
    margin-bottom: 0;
}

.cont_rsAU{
    display: flex;
    flex-direction: row;
    margin-top: 0;
}

.informacionsecc2AU h3{
    margin-bottom: 10px;
}

.iconorsAU{
    width: 40px;
    margin-right: 10px;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

  @media (min-width: 769px) and (max-width: 960px) {
  .secc2_AboutUs{
    display: flex;
    flex-direction: column;
  }

  .informacionsecc2AU{
    text-align: center;
    margin-top: 50px;
  }

  .cont_rsAU{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconorsAU{
    width: 80px;
  }
  }
  
  
  /* Estilos para tabletas */
  @media (min-width: 601px) and (max-width: 768px) {
    .secc2_AboutUs{
        display: flex;
        flex-direction: column;
      }
    
      .informacionsecc2AU{
        text-align: center;
        margin-top: 50px;
      }
    
      .cont_rsAU{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .iconorsAU{
        width: 80px;
      }
  }
  
  /* Estilos para dispositivos móviles */
  @media (min-width: 420px) and (max-width: 599px)  {
    .secc2_AboutUs{
        display: flex;
        flex-direction: column;
        margin-left: 4%;
      }

      .formulario_secc2AU{
        margin-left: 0px;
      }
    
      .informacionsecc2AU{
        text-align: center;
        margin-top: 50px;
      }

      .informacionsecc2AU iframe{
        width: 400px;
        margin-bottom: 30px;
      }
    
      .cont_rsAU{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .iconorsAU{
        width: 50px;
      }
  }
  
  @media (max-width: 419px){

    .AboutUs_secc1{
      padding-right: 0;
      padding-left: 0;
    }

    .secc2_AboutUs{
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }

    .formulario_secc2AU{
      margin-left: 0px;
      margin-right: 0;
      width: 318px;
    }
  
    .informacionsecc2AU{
      text-align: center;
      margin-top: 50px;
    
    }

    .imagen_cont_secc1{
      width: 80%;
    }

    .informacionsecc2AU iframe{
      width: 370px;
      margin-bottom: 30px;
      margin-right: 0;
    }
  

    .cont_rsAU{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .iconorsAU{
      width: 50px;
    }
  }