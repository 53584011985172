.accRapida_DB{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    height: auto;
    padding: 0;
    padding-top: 0;
    margin-left: 2%;
    margin-right: 2%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.botonCI_accRapida{
    width: 100%;
    height: 3em;
    font-size: 1em;
    border: none;
    background-color: rgb(3, 79, 54);
    color: white;
}


.campo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    margin-top: 0px;
}

.campo label{
    margin-right: 10px;

}

.campo p{
    margin-top:0px;
    margin-bottom: 4px;
}

.campo select,
.campo input{
    height: 1.5em;
    width: 40px;
}

.noches_form{
    margin-bottom: 00px;
}

.tipoHab_DB_cont{
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.tipoHabTag{
    background-color: antiquewhite;
    padding: 4px 7px;
    font-size: 0.9em;
    border-radius: 10px;
    width: 70%;
    font-weight: 400;
}


.personas_AccRap{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.personas_AccRap a{
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 5px;
padding-right:13%;
}

.personas_AccRap p{
margin-top: 0px;
}

.personas_AccRap input{
    width: 40px;
    height: 1.5em;
}

.nombreHue_DB_cont{
    margin-left: 10px;
    margin-top: 0px;
}

.nombreHue_DB_cont p{
margin-top: 0;
}

.nombreHue_DB_cont input{
    height: 1.5em;
    width: 90%;
}

.formaPago_DB_cont{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    align-items: center;
}

.formaPago_DB_cont p{
    margin-right: 10px;
}

.formaPago_DB_cont select{
    height: 2em;
    width: 50%;
}


.campo-total{
position: relative;
bottom: 30px;
width: 90%;
margin-left: 5%;
}

.campo-total hr{
    margin-top: 0;
    margin-bottom: 0;
}

.campo-total a{
display: flex;
height: 2em;
flex-direction: row;
align-items: center;
justify-content: space-between;
}

.botonProceder{
    background-color: #61908A;
    color: white;
    border: none;
    padding: 5px 30px;
    width: 80%;
    margin-left: 10%;
    cursor: pointer;
    position: relative;
    bottom: 30px;
}

.botonProceder:hover{
    background-color: #266058;
}

.contBotonesAR{
    width: 100%;
    display: flex;
    flex-direction:column;
    gap:10px;
}

.botonEnviarCI:hover{
background-color: rgb(153, 153, 153);
}

.botonSubmintCI:hover{
    background-color: #266058;
    color: white;
}

.botonCancelarCI{
background-color: white;
}
.botonCancelarCI:hover{
    color: red;
    }

@media (max-width: 1000px) {
    .accRapida_DB{
        display: none;
    }
}