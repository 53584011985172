.contendor_buscarHabitaciones{
    background-color: #FFFFFF;
    color: #674A14;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: auto;
    border-top: 10px solid #FAD8A2;    /* Grosor y color del borde superior */
    border-right: 2px solid #FAD8A2; /* Grosor y color del borde derecho */
    border-bottom: 2px solid #FAD8A2; /* Grosor y color del borde inferior */
    border-left: 2px solid #FAD8A2;
    margin-bottom: -10%;
    margin-top: 15%;
    padding: 30px;
}

.input_buscador{
    display: flex;
    flex-direction: column;
}

.input_buscador label{
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: 5px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  
}


/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 768px) {
  .contendor_buscarHabitaciones{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: -20%;
  }
}

/* Estilos para dispositivos móviles */
@media (max-width: 600px) {
    .contendor_buscarHabitaciones{
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-bottom: -7%;
      }
}