.galeriaInicio_contAU {
    background-color: #ffeabd;
    margin-top: 0;
    text-align: center;
    color: rgb(81, 49, 1);
    margin-top: -4px;
    padding-top: 20px;
}

.contImagenes {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas de igual tamaño */
    grid-template-rows: repeat(2, auto); /* 2 filas de altura automática */
    justify-content: center;
    margin-left: 10%;
    padding-bottom: 50px;
}

.fotoGaleria_home {
    padding: 5px;
    font-size: 18px;
    width: 300px;
    height: 200px;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.3s;
}

.fotoGaleria_home:hover {
    transform: scale(1.05);
}

/* Estilo del popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 4, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    position: relative;
    background: #ffffff;
    max-width: 90%;
    height: 480px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.popupContent img {
    width: 100%;
    max-width: 100%;
    max-height: 480px;
    object-fit: cover;
}

.botonCerrar_Galeria {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(255, 255, 255);
    color: #143b36;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    align-items:center ;
    justify-content: center;
}

.botonCerrar_Galeria:hover {
    background: rgb(201, 201, 201);
    cursor: pointer;
}
