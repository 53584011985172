/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.cont_ParkSys{
  display: flex;
  flex-direction: row;
}

/* Estilo de la columna izquierda */
.left-panel {
  width: 25%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-panel h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.datosVehiculo{
 margin-right: 20PX;
}

.vehicle-type-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.vehicle-type button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  padding: 3px;
  margin: 0 5px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vehicle-type-button.active {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.vehicle-type-button:hover {
  background-color: #656667;
}

.vehicle-form {
  display: flex;
  flex-direction: column;
}

.vehicle-form label {
  margin-top: 10px;
  font-size: 14px;
}

.vehicle-form input,
.vehicle-form select {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.vehicle-form button {
  margin-top: 20px;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.vehicle-form button:hover {
  background-color: #218838;
}

/* Estilo de la columna central */
.center-panel {
  width: 25%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.center-panel h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.summary {
  font-size: 14px;
  line-height: 1.6;
  margin-right: 20px;
  border: 0.5px gray solid;
  padding-right: 20px;
  padding-left: 20px;
  height: auto;
  border-radius: 20px;
  width: 60%;
}

button.print-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button.print-button:hover {
  background-color: #0056b3;
}

/* Estilo de la columna derecha */
.right-panel {
  width: 45%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.right-panel h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.espaciosEstacionamiento {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;  /* Muestra la barra de desplazamiento solo cuando sea necesario */
  gap: 16px;
  height: 450px;  /* Asegúrate de definir una altura para activar el desplazamiento */
}

.parking-distribution h2{
margin-top: 0;
}

.slot {
  flex: 1 1 calc(30% - 16px); /* Cada elemento ocupa 25% del ancho menos el espaciado */
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
  background-color: #f0f0f0;
  padding: 8px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}



.slot.occupied {
  background-color: #aee595;
  border-color: #f5c6cb;
}

.slot:hover {
  background-color: #f2d3b4;
  color: white;
  font-weight: 800;
}

.slot span {
  font-size: 12px;
}

/* Ventana emergente */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 400px;
  max-width: 90%;
}

.modal h3 {
  margin-top: 0;
  font-size: 18px;
}

.modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.modal .modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.modal button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.modal .exit-button {
  background-color: #dc3545;
  color: #fff;
}

.modal .exit-button:hover {
  background-color: #c82333;
}

.modal .cancel-button {
  background-color: #6c757d;
  color: #fff;
}

.modal .cancel-button:hover {
  background-color: #5a6268;
}

/* Fondo de la ventana modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

