.contenedorPRomocionMarina{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.imgMArina{
    width: 340px;
    height: 37vh;
    object-fit: cover;
}

.textoMarina{
    color: #2885B6;
    text-align: left;
}

.textoMarina h1{
    font-size: 6em;
    margin-top: 20px;
}

.textoMarina h2{
    font-size: 2em;
    margin-bottom:0 ;
    margin-top: 0;
}

.logoMarina{
    width: 600px;
    margin-top: 60px;
}

.redesMarina{
    display: flex; 
    justify-content: flex-end;
    padding-right: 15%;
}

.logoRSMarina{
    width: 40px;
    margin-left: 10px;
}

@media (min-width: 1000px) and (max-width: 1263px) {
    .logoMarina{
        width: 400px;
        margin-top: 60px;
    }

    .textoMarina h2{
        font-size: 2em;
        margin-bottom:0 ;
        margin-top: 0;
    }
}


/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 999px) {
    .contenedorPRomocionMarina{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .imgMArina{
       width: 100%;
       height: 40vh;
       object-fit: cover;
    }

    .textoMarina{
        display: flex;
        flex-direction: row;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .textoMarina h1{
        font-size: 9em;
        margin-top: 0;
    }

    .textoMarina h2{
        width: 100%;
    }

    .logoyredesMarina{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .logoMarina{
        margin-top: 0;
    }
}

/* Estilos para dispositivos móviles */
@media (min-width: 420px) and (max-width: 599px) {
    .contenedorPRomocionMarina{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .imgMArina{
       width: 100%;
       height: 40vh;
       object-fit: cover;
    }

    .textoMarina{
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .textoMarina h1{
        font-size: 9em;
        margin-top: 0;
    }

    .textoMarina h2{
        width: 100%;
        text-align: center;
        font-size: 1.5em;
    }

    .logoyredesMarina{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 40px;
    }

    .logoMarina{
        width: 350px;
        margin-top: 0;
    }
}

@media (max-width: 419px){
    .contenedorPRomocionMarina{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .imgMArina{
       width: 100%;
       height: 40vh;
       object-fit: cover;
    }

    .textoMarina{
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .textoMarina h1{
        font-size: 9em;
        margin-top: 0;
    }

    .textoMarina h2{
        width: 100%;
        text-align: center;
        font-size: 1.5em;
    }

    .logoyredesMarina{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 40px;
    }

    .logoMarina{
        width: 220px;
        margin-top: 0;
    }
}