/* Estilos generales */
.contenidoPRoom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}

.encabezado_PRoom {
  color: #143B36;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 5%;
  width: 100%;
  height: 30vh;
  background-image: url("./bannersuperior_rooms.svg");
  background-size: cover;
  background-position: center;
}

.principalPRoom {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 80%;
  margin-left: 12%;
  flex-wrap: wrap;
}

.galeriaRoom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilos para la galería */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.main-image img {
  width: 100%;
  height: 50vh;
  border-radius: 8px;
  margin-bottom: 16px;
  object-fit: cover;
}

.thumbnail-container {
  display: flex;
  gap: 6px;
  margin-left: 0%;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
}

.thumbnail {
  width: 30%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s, border 0.2s;
}

.thumbnail.active {
  border: 2px solid #dab085;
}

.thumbnail:hover {
  transform: scale(1.1);
  border: 2px solid #dab085;
}

/* Estilos adicionales */
.infoRoomHab {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
}

.infoRoomHab p {
  margin-top: 0;
}

.precioReservar {
  text-align: left;
  border: 0.5px solid #CECBCB;
  padding: 20px;
}

.precioReservar a {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.precioReservar a h3,
.precioReservar a h4 {
  margin-top: 0;
}

.precioReservar a h3 {
  color: #A88452;
}

.precio {
  font-size: 24px;
  color: #333;
}

.botonReservarRoom {
  background-color: #61908A;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.botonReservarRoom:hover {
  background-color: #426b66;
}

.contComodidades {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  border: 0.5px solid #CECBCB;
  padding: 20px;
}

.tagComodidades {
  background-color: #E6E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 5px 30px;
  border-radius: 40px;
  font-size: 0.7em;
}

.iconoComodidades {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.separador1Room {
  width: 80%;
}

.infoGral_Room {
  width: 40%;
  margin-left: 12%;
  align-items: center;
  justify-content: center;
}

.linksRoom {
  display: flex;
  flex-direction: row;
  margin-top: 4%;
  margin-bottom: 5%;
}

.AboutusRoom,
.AboutusRoom2 {
  width: 50%;
  padding: 30px;
  padding-right: 50px;
  height: 30vh;
  color: white;
  font-size: 1.2em;
}

.botonRoomsLinks {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 4px;
}

.botonRoomsLinks:hover {
  background-color: rgba(255, 255, 255, 0.115);
}

.AboutusRoom {
  background-image: url("./veraboutus_banner.svg");
  background-position: center;
}

.AboutusRoom2 {
  background-color: #143B36;
}

/* Estilos para tabletas */
@media (max-width: 1024px) {
  .principalPRoom {
      flex-direction: column;
      width: 100%;
      margin-left: 0;
  }

  .infoRoomHab {
      width: 100%;
      flex-direction: column;
  }

  .main-image {
      width: 100%;
      height: auto;
  }

  .thumbnail-container {
      justify-content: center;
  }

  .AboutusRoom p,
  .AboutusRoom2 p {
      font-size: 1em;
  }
}

/* Estilos para dispositivos móviles */
@media (min-width: 601px) and (max-width: 768px) {
  
  .principalPRoom {
      display: flex;
      flex-direction: column;
  }

  .main-image {
      width: 100%;
      height: auto;
  }

  .thumbnail-container {
      justify-content: center;
  }

  .infoRoomHab {
      width: 100%;
      flex-direction: column;
      margin-bottom: 30px;
  }

  .infoGral_Room {
      width: 100%;
      text-align: center;
  }

  .linksRoom {
      flex-direction: column;
      margin-top: 40px;
  }

  .AboutusRoom,
  .AboutusRoom2 {
      width: 100%;
      padding: 20px;
  }

  .AboutusRoom p,
  .AboutusRoom2 p {
      font-size: 0.9em;
  }
}

@media (min-width: 420px) and (max-width: 600px) {
  .principalPRoom {
    display: flex;
    flex-direction: column;
}

.main-image {
    width: 100%;
    height: auto;
}


.main-image img{
  width: 100%;
  height: auto;
}


.thumbnail-container {
    justify-content: center;
}

.infoRoomHab {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
}

.infoGral_Room {
    width: 100%;
    text-align: center;
}

.linksRoom {
    flex-direction: column;
    margin-top: 40px;
}

.AboutusRoom,
.AboutusRoom2 {
    width: 100%;
    padding: 20px;
}

.AboutusRoom p,
.AboutusRoom2 p {
    font-size: 0.9em;
}
}

@media (max-width: 420px) {
  .principalPRoom {
    display: flex;
    flex-direction: column;
}

.gallery-container{
  width: 370px;
}

.main-image {
    width: 100%;
    height: auto;
}

.main-image img{
  width: 100%;
}


.thumbnail-container {
    justify-content: center;
}

.infoRoomHab {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
}

.infoGral_Room {
    width: 80%;
    text-align: center;
}

.linksRoom {
    flex-direction: column;
    margin-top: 40px;
}

.AboutusRoom,
.AboutusRoom2 {
    width: 90%;
    padding: 20px;
}

.AboutusRoom p,
.AboutusRoom2 p {
    font-size: 0.9em;
}
}