.paginaAU {
  background-color: antiquewhite;
}

.barra_paginaAU {
  background-color: #143b36;
  height: 420px;
}

.AboutUs_secc1 {
  display: flex;
  flex-direction: row;
  padding-left: 13.5%;
  padding-right: 13.5%;
  margin-top: -15%;
}

.textoBlanco_secc1 {
  color: white;
}

.textoBlancop_secc1 {
  color: white;
  margin-bottom: 70px;
}

.enlacesContenido_secc1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  text-align: center;
  margin-top: 60px;
}

.enlace_secc1AU a {
  background-color: #75bab1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 25px;
  padding-bottom: 7px;
  border-radius: 30px 30px 0 0;
}

.icono_enlace1_secc1AU {
  width: 25px;
  height: 25px;
}

.imagen_cont_secc1 {
  width: 80%;
  margin-left: 50px;
}

.imagenAU1 {
  width: 100%;
}

@media (min-width: 995px) and (max-width: 1200px) {
  .AboutUs_secc1 {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -19%;
  }

  .imagen_cont_secc1 {
    width: 100%;
  }
}

/* Estilos para tabletas */
@media (min-width: 870px) and (max-width: 994px) {
  .AboutUs_secc1 {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -23%;
  }

  .imagen_cont_secc1 {
    width: 100%;
  }

  .barra_paginaAU {
    background-color: #143b36;
    height: 420px;
  }
}

@media (min-width: 601px) and (max-width: 869px) {
  .AboutUs_secc1 {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -30%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .imagen_cont_secc1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .imagenAU1 {
    width: 100%;
    margin-left: -8%;
  }

  .barra_paginaAU {
    background-color: #143b36;
    height: 420px;
  }

  .textoContenido_secc1 {
    width: 100%;
  }

  .enlacesContenido_secc1 {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

/* Estilos para dispositivos móviles */
@media (min-width: 420px) and (max-width: 599px) {
  .AboutUs_secc1 {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .imagen_cont_secc1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .imagenAU1 {
    width: 70%;
    margin-left: -8%;
  }

  .barra_paginaAU {
    background-color: #143b36;
    height: 420px;
  }

  .textoContenido_secc1 {
    width: 80%;
    margin-left: 7%;
  }

  .enlacesContenido_secc1 {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .formulario_secc2AU {
    width: 100%;
    margin-left: 0;
  }

  .contendio_secc1 {
    margin-top: -20%;
  }
}

@media (max-width: 419px) {
  .AboutUs_secc1 {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .imagen_cont_secc1 {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .imagenAU1 {
    width: 70%;
    margin-left: -8%;
  }

  .barra_paginaAU {
    background-color: #143b36;
    height: 450px;
    padding-bottom: 20px;
  }

  .textoBlanco_secc1 {
    margin-top: -90%;
  }

  .textoBlancop_secc1 {
    color: white;
    margin-bottom: 20%;
  }

  .textoContenido_secc1 {
    width: 80%;
    margin-left: 7%;
  }

  .enlacesContenido_secc1 {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .formulario_secc2AU {
    width: 100%;
  }
}
