.home_bannerprinc {
  background-image: url("../componentes/Home/saladedia.jpg");
  height: 600px;
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: bottom; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed;
  padding-top: 7%;
}

.home h1 {
  margin-bottom: 0;
  font-size: 3em;
}

.home h2 {
  margin-top: 3px;
}

.boton_reservar {
  background-color: #00afaa;
  border: none;
  color: white;
  font-size: 1.3em;
  padding: 10px 20px;
  cursor: pointer;
}

.boton_reservar:hover {
  background-color: #00918c;
  border: none;
  color: white;
  font-size: 1.3em;
  padding: 10px 20px;
  cursor: pointer;
}

.secc2_home {
  background-color: #143b36;
  color: white;
  text-align: center;
}

.separador {
  width: 130px;
  margin-top: 7%;
}

.secc2_home h1 {
  font-size: 2em;
}

.contennedor_HabitacionesMuestra {
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  width: 80%;
  justify-content: space-around;
  margin-left: 10%;
  margin-top: 40px;
}

.habitación_Muestra {
  width: 300px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.345);
  overflow: hidden;
  border-radius: 10px;
}

.imagen_habMues {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}

.habitación_Muestra h3 {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 20px;
}

.habitación_Muestra h4 {
  margin-bottom: 3px;
  margin-top: 0;
  margin-left: 20px;
  font-weight: 300;
}

.botonMas_habMues,
.botonReservar_habMues {
  background-color: #00afaa;
  border-radius: 5px;
  color: white;
  border: NONE;
  cursor: pointer;
  font-weight: 300;
  margin-top: 20px;
}

.botonMas_habMues:hover,
.botonReservar_habMues:hover {
  background-color: #01837f;
  border-radius: 5px;
  color: white;
  font-weight: 500;
}

.botones_habMues {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  gap: 10px;
  width: 60%;
  margin-left: 17%;
  margin-bottom: 15px;
}

.botonReservar_habMues {
  font-size: 1.1em;
  padding: 5px 18px;
}

.separadorInferior_habMues {
  width: 80%;
  z-index: 10;
}

.contenedor_AU {
  display: flex;
  flex-direction: row;
  background-color: #092e29;
  padding-top: 80px;
  margin-top: -20px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 70px;
}

.imagen_contAU {
  width: 40%;
  margin-right: 50px;
}

.texto_AUhome h3 {
  background: linear-gradient(
    45deg,
    #c7a53f,
    #866920,
    #cf9f47
  ); /* Degradado dorado */
  background-clip: text; /* Recorta el fondo al texto (para navegadores basados en WebKit) */
  -webkit-text-fill-color: transparent; /* Hace el texto transparente para que se vea el degradado */
  margin-bottom: 0;
  font-size: 1.5em;
  font-weight: 700;
}

.texto_AUhome h1 {
  font-size: 2.5em;
  color: white;
  margin-top: 2%;
  margin-bottom: 5%;
}

.texto_AUhome p {
  font-size: 1.3em;
  margin-top: 0px;
  color: white;
}

.botonAu_inicio {
  background-color: #c09f5f;
  border: none;
  color: white;
  font-size: 1.5em;
  padding: 5px 25px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 200;
}

.botonAu_inicio:hover {
  background-color: #9d782c;
}

/* Estilos para escritorios pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
  .contennedor_HabitacionesMuestra {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 80%;
    justify-content: space-around;
    margin-left: 14%;
    margin-top: 40px;
  }
  
  .habitación_Muestra {
    width: 90%;
    display: flex;
    flex-direction: row;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.345);
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .imagen_habMues{
    width: 50%;
  }

  .botones_habMues{
    width:80%;
    margin-top: 30px;
  }

 

}

/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 768px) {
  .home h1 {
    margin-bottom: 0;
    font-size: 2.5em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
  }

  .home_bannerprinc h2{
    font-size: 1.4em;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
  }

  .contennedor_HabitacionesMuestra {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 80%;
    justify-content: space-around;
    margin-left: 14%;
    margin-top: 40px;
  }
  
  .habitación_Muestra {
    width: 90%;
    display: flex;
    flex-direction: row;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.345);
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .imagen_habMues{
    width: 50%;
  }

  .botones_habMues{
    width:80%;
    margin-top: 30px;
  }

  .contenedor_AU {
    display: flex;
    flex-direction: column;
  }

  .imagen_contAU{
    width: 90%;
    margin-left: 7%;
  }
}

/* Estilos para dispositivos móviles */
@media (min-width: 420px) and (max-width: 599px)  {

  
  .home h1 {
    margin-bottom: 0;
    font-size: 3.3em;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
  }

  .home_bannerprinc{
    padding-top: 30%;
  }

  .home_bannerprinc h2{
    font-size: 1.2em;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
    margin-bottom: 10px;
  }

  .secc2_home h1{
 font-size: 2em;
  }

  .contennedor_HabitacionesMuestra {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 90%;
    justify-content: space-around;
    margin-left: 10%;
    margin-top: 40px;
  }
  .habitación_Muestra {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.345);
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 0;
  }

  .imagen_habMues{
    width: 100%;
    height: 28vh;
  }

  .botones_habMues{
    margin-left: 50px;
    width:65%;
    margin-top: 10px;
  }



  .contenedor_AU {
    display: flex;
    flex-direction: column;
  }

  .imagen_contAU{
    width: 90%;
    margin-left: 7%;
  }
  
}

@media (max-width: 419px){

  
  .home h1 {
    margin-bottom: 0;
    font-size: 3.3em;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
  }

  .home_bannerprinc{
    padding-top: 30%;
  }

  .home_bannerprinc h1{
    font-size: 2em;
  }

  .home_bannerprinc h2{
    font-size: 1.2em;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.499);
    margin-bottom: 10px;
  }

  .secc2_home h1{
 font-size: 1.5em;
  }

  .contennedor_HabitacionesMuestra {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 90%;
    justify-content: space-around;
    margin-left: 10%;
    margin-top: 40px;
  }
  
  .habitación_Muestra {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.345);
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 0;
  }

  .imagen_habMues{
    width: 100%;
    height: 28vh;
  }

  .botones_habMues{
    margin-left: 50px;
    width:65%;
    margin-top: 10px;
  }


  .contenedor_AU {
    display: flex;
    flex-direction: column;
  }

  .imagen_contAU{
    width: 70%;
    margin-left: 7%;
  }

  .logoMarina{
    width: 30px;
  }
}