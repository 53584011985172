.barra_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    font-family: "Raleway", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  .logoNavbar {
    width: 150px;
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .secc_cont {
    display: flex;
    justify-content: space-between;
    width: 40%;
    background-color: rgba(0, 0, 0, 0.681);
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
  }
  
  .boton_seccion {
    color: white;
    text-decoration: none;
  }
  
  .contacto_cont {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }
  
  .boton_contacto {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: solid 1px white;
    height: 3em;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    text-decoration: none;
  }
  
  /* Estilos para el menu hamburguesa */
  .burger_menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
    align-items: center;
  }
  
  .burger_line {
    width: 100%;
    height: 4px;
    background-color: white;
    margin: 3px 0;
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {

    .barra_nav{
        width: 100%;
        background-color: rgba(1, 18, 10, 0.9);
        align-items: center
    }

    .burger_menu{
        margin-right:30px  ;
    }

    .burger_line{
        width: 30px;
        border: 2px solid  rgb(255, 255, 255);
        margin-right:50px  ;
    }

    .secc_cont {
      display: none;
      flex-direction: column;
      text-align: center;
      align-items: center;
      height: 30vh;
      padding-bottom: 100px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 60px;
      padding-left: 0;
    }
  
    .secc_cont.active {
      display: flex;
      flex-direction: column;
      font-size: 2em;
    }
  
    .burger_menu {
      display: flex;
    }

    .oculto{
        display: none;
    }
  }
  
  @media (max-width: 480px) {
    .logoNavbar {
      width: 120px;
    }
  
    .boton_seccion {
      padding: 10px;
      font-size: 1em;
    }
  
    .boton_contacto {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  