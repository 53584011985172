.PagePrinc_DB {
    flex: 1;
    padding: 20px;
    margin-bottom: 10px;
  }

  .encabezado_DB{
    margin-top: 0;
  }

  .ecabezadoPrincipal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .btn-nuevo {
    background-color: #61908A;
    height: 2.5em;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  
  .resumen {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .tarjeta {
    width: 20%;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    color: black;
  }
  
  .verde { background-color: #d4f8d4; }
  .rosa { background-color: #fdeeee; }
  .azul { background-color: #eaf4ff; }
  .amarilla { background-color: #fef9d8; }
  
  .lista-huespedes table {
    width: 100%;
    border-collapse: collapse;
  }


  .lista-huespedes th, .lista-huespedes td {
    border-bottom: 1px solid #ddd;
    padding: 2px;
    text-align: center;
  }
  
  .etiqueta {
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  
  .azul { background-color: #D9F0FF; 
color: #4384FF;
}
  .morada { background-color: #E9E8FC; 
color: #DA8DF7;
}
  .naranja { background-color: #FDEEBB; 
color: #FFB743;
}

  /* Tabla */
table {
    width: 100%;
    border-collapse: collapse;
    height: 50vh;
  }
  
  thead {
    background-color: #fffefe; /* Color de fondo del encabezado */
    position: sticky;
    top: 0; /* Fija el encabezado en la parte superior */
    z-index: 10;
  }
  
  
  /* Scroll en el tbody */
  tbody {
    display: block;
    max-height: 300px; /* Altura máxima para la lista */
    overflow-y: auto; /* Habilita el scroll vertical */
  }
  
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 2em;
    font-size: 0.9em;
  }

  tr:hover{
    background-color: #f8f7f7;
    cursor: pointer;
  }

  .botonER{
    background-color: none ;
  }

  .eliminarReservacion:hover{
    color: green;

  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .popup-content form {
    display: flex;
    flex-direction: column;
  }
  
  .popup-content button {
    margin-top: 10px;
  }
  
  /* Modal */
.modalPP {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalPP-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modalPP-actions button {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

/*MODAL RESERVACIONES*/

.popupRes-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupRes-box{
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.popupRes-box button{
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}


.popupRes-box button:hover{
  background-color: rgb(173, 173, 173);
}

.popupRes-box form{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.adNin_NuevaRes{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.adNin_NuevaRes a{
  display: flex;
  flex-direction: column;
}

.input_precioReservacion{
  border: none;
  text-align: center;
  font-size: 1.2em;
  margin-left: 13px;
}

.boton_VerdeNR{
  background-color: rgb(86, 168, 61);
  color: white;
  cursor: pointer;
  align-items: center;
}

.boton_VerdeNR:hover{
  background-color: rgb(55, 135, 31) !important;
  color: white;
  cursor: pointer;
}

.botonCancelacionRes{
  background-color: orange;
  color: white;
}

.botonCancelacionRes:hover{
  background-color: rgb(195, 83, 3) !important;
  color: white;
}