.barra_paginaBlog {
    background-color: #143b36;
    height: 20%;
    padding-top: 1px;
    padding-left: 10%;
    padding-bottom:1%;
    color: white;
  }
  
  .barra_paginaBlog h1 {
    margin-top: 140px;
    font-size: 3em;
    margin-bottom: 0;
  }
  
  .barra_paginaBlog p {
    width: 50%;
  }

  .encabezado_articulo{
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 120px;
    width: 80%;
    margin-left: 10%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
    background-image: url("https://i0.wp.com/stretchingmexico.com/wp-content/uploads/2024/07/placeholder.webp?fit=1200%2C800&ssl=1");
    color: white;
    border-radius: 20px;
  }

  .encabezado_articulo h1{
    margin-bottom: 0;
  }

  .encabezado_articulo p{
    margin-top: 0;
  }

  .encabezado_articulo1{
    background-image: url("https://experiencia.edomex.gob.mx/sites/default/files/2020-08/Valle%204.jpg");
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 120px;
    width: 80%;
    margin-left: 10%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
    color: white;
    border-radius: 20px;
  }

  .encabezado_articulo1 h1{
    margin-bottom: 0;
  }

  .encabezado_articulo1 p{
    margin-top: 0;
  }

  .encArt2{
    background-image: url("https://www.vecparapente.com/uploads/8/5/6/1/85618026/foto-web7_orig.jpg") !important;
    background-position: center;
  }

  .encArt3{
    background-image: url("https://img.optimalcdn.com/www.posta.com.mx/2024/10/6c040ce2219f0d12a77eff1147c4eb1f226a7e17/Festival_de_las_Almas_2024_en_Valle_de_Bravo_lo_que_sabemos.webp") !important;
  }

  .encArt4{
    background-image: url("https://www.cocinadelirante.com/sites/default/files/images/2024/03/tacos-de-bistec-con-papas-estilo-taqueria-receta-rendidora.jpg") !important;
    background-position: center;
  }

.encArt5{
  background-image: url("https://media.glamour.mx/photos/644c36cf6d1283eafdba475d/16:9/w_2991,h_1682,c_limit/valle-de-bravo-destino.jpg") !important;
  background-position: center;
}


  .seccion1Articulo,
  .seccion2Articulo{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 10%;
  }

  .texto1Articulo{
    width: 50%;
    margin-right: 50px;
  }

  .subtituloArticulo{
    margin-left: 10%;
  }

  .imagenArticulo_cont{
    width: 40%;
  }

  .imagenArticulo{
    width: 100%;
    border-radius: 10px;
  }

  .iac2{
    margin-right: 50px;
  }

  .piedeimagenArticulo{
    font-size: 0.7em;
  }

  .texto_adicional{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 10%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
  
  }
  
  
  /* Estilos para tabletas */
  @media (min-width: 601px) and (max-width: 768px) {
    .barra_paginaBlog{
      padding-left: 7%;
    }

    .barra_paginaBlog p {
      width: 70%;
    }

    .encabezado_articulo1{
      height: 180px;
      background-position: center;
      margin-left: 7%;
    }

    .encabezado_articulo{
      height: 180px;
      margin-left: 7%;
    }

    .seccion1Articulo{
      display: flex;
      flex-direction: column;
      margin-left: 7%;
    }

    .texto1Articulo{
      width: 90%;
    }

    .imagenArticulo_cont{
      width: 90%;
    }

    .seccion2Articulo{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  
  /* Estilos para dispositivos móviles */
  @media (min-width: 420px) and (max-width: 600px) {
  
    .barra_paginaBlog{
      padding-left: 7%;
    }

    .barra_paginaBlog p {
      width: 90%;
    }

    .encabezado_articulo1{
      height: 180px;
      background-position: center;
      margin-left: 7%;
    }

    .encabezado_articulo{
      height: 180px;
      margin-left: 7%;
    }

    .seccion1Articulo{
      display: flex;
      flex-direction: column;
      margin-left: 7%;
    }

    .texto1Articulo{
      width: 90%;
    }

    .imagenArticulo_cont{
      width: 90%;
    }

    .seccion2Articulo{
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 420px) {
    .barra_paginaBlog{
      padding-left: 7%;
    }

    .barra_paginaBlog p {
      width: 90%;
    }

    .encabezado_articulo1{
      height: 280px;
      background-position: center;
      margin-left: 7%;
    }

    .encabezado_articulo{
      height: 280px;
      margin-left: 7%;
    }

    .seccion1Articulo{
      display: flex;
      flex-direction: column;
      margin-left: 7%;
    }

    .texto1Articulo{
      width: 90%;
    }

    .imagenArticulo_cont{
      width: 90%;
    }

    .seccion2Articulo{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  