.galeriaPrincipal_cont {
    background-color: #143b36;
    text-align: center;
    color: white;
    margin-top: -4px;
    padding-top: 100px;
}

.contImagenes {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas de igual tamaño */
    grid-template-rows: repeat(2, auto); /* 2 filas de altura automática */
    justify-content: center;
    margin-left: 10%;
    padding-bottom: 50px;
}

.galeriaInicio_cont {
    background-color: #143b36;
    margin: 0;
    text-align: center;
    color: white;
    padding-top: 20px;
}

.galeriaInicio_cont h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.contImagenes {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas */
    gap: 15px; /* Espaciado entre imágenes */
    margin: 0 auto;
    padding-bottom: 50px;
}

.fotoGaleria_home {
    width: 100%;
    height: auto;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.3s;
}

.fotoGaleria_home:hover {
    transform: scale(1.05);
}

/* Estilo del popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 4, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    position: relative;
    background: #ffffff;
    max-width: 90%;
    height: auto;
    max-height: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.popupContent img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.botonCerrar_Galeria {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(255, 255, 255);
    color: #143b36;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.botonCerrar_Galeria:hover {
    background: rgb(201, 201, 201);
}

/* Media Queries para responsividad */
@media (max-width: 1024px) {
    .contImagenes {
        grid-template-columns: repeat(3, 1fr); /* 3 columnas */
    }

    .galeriaInicio_cont h1 {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .contImagenes {
        grid-template-columns: repeat(2, 1fr); /* 2 columnas */
    }

    .galeriaInicio_cont h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .contImagenes {
        grid-template-columns: 1fr; /* 1 columna */
    }

    .galeriaInicio_cont h1 {
        font-size: 1.2rem;
    }

    .popupContent {
        max-height: 70%;
        overflow: auto;
    }

    .botonCerrar_Galeria {
        top: 5px;
        right: 5px;
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
}
