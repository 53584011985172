.seccion_ContadorHusespedes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card_Contador{
    position: relative;
    width: 19%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 4px;
    border-radius: 13px;
    text-align: right;
    
}

.card_Contador h2{
    font-size: 1em;
    margin-bottom: 0;
}

.card_Verde{
    background-color:#DCF8E0 ;
    color: green;
}

.card_Rojo{
    background-color: #FEF2F2;
    color: rgb(161, 2, 2);
}

.card_Azul{
    background-color: #EFF6FF;
    color: rgb(2, 75, 135);
}

.card_Amarillo{
    background-color: #FEFBE8;
    color: rgb(255, 213, 0);
}

.card_Contador a{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1em;
}

.card_Contador a h3{
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-weight: 100;
    font-size: 1em;
}

.icono_Contador{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.tg_Contador{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2em;
}


.info-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.504);
    border-radius: 100%;
    font-size: 10px;
    font-weight: 700;
    color: #00000075;
    cursor: pointer;
    opacity: 50%;
  }
  
  .info-btn:hover {
    color: #007BFF;
    border: 1px solid #007BFF;
  }

  