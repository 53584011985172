.paginaAU {
  background-color: white;
}

.barra_paginaBlog {
  background-color: #143b36;
  height: 20%;
  padding-top: 1px;
  padding-left: 10%;
  padding-bottom:1%;
  color: white;
}

.barra_paginaBlog h1 {
  margin-top: 140px;
  font-size: 3em;
  margin-bottom: 0;
}


.principalesBlog_cont {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  margin-left: 9%;
  margin-right: 7%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.articulo_principal{
    background-repeat: no-repeat;
    background-size: cover;
    height: 280px;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items:flex-end;
    color: white;
}

.artPrinc1{
  background-image: url("https://www.ladobe.com.mx/wp-content/uploads/2016/03/Valle-de-Bravo_PepeJimenez.jpg");
}

.artPrinc2{
  background-image: url("https://media-cdn.tripadvisor.com/media/attractions-splice-spp-674x446/0a/9b/a6/31.jpg");
}

.articulo_principal p{
    width: 80%;
}

.botonArtPrinc_cont{
    text-align: center;
}

.flechaArtPrinc{
width: 30px;
}

.separadorBlogPrincipal{
    width: 80%;
    margin-bottom: 20px;
}


.articulosSecundarios{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  margin-left: 10%;
  margin-bottom: 40px;
}

.articuloSecundario{
  width: 33%;
}

.articuloSecundario h3{
  margin-bottom: 0;
}

.articuloSecundario p{
  margin-top: 10px;
  width: 80%;
}

.imagenArtSecundario{
  width: 100%;
  height: 220px;
  border-radius: 20px;
  object-fit: cover;
}

.botonArtSecundario{
background-color: #143b36;
color: aliceblue;
width: 120px;
height: 2em;
border-radius: 20px;
border: none;
cursor: pointer;
}

.botonArtSecundario:hover{
  background-color: #1a4b44;
  font-weight: 600;
}

/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 790px) {
  .principalesBlog_cont{
      display: flex;
      flex-direction: column;
  }

  .articulosSecundarios{
      display: flex;
      flex-direction: column;
  }

  .articuloSecundario{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
  }

  .imagenArtSecundario{
    width: 50%;
    margin-right: 3%;
  }

  .articuloSecundario a{
    display: flex;
    flex-direction: column;
  }

  .articuloSecundario h3{
    margin-top: 0;
  } 

  .articuloSecundario p{
    width: 100%;
    font-size: 0.9em;
  }

  .botonArtSecundario{
    align-self: flex-end;
  }
}

/* Estilos para dispositivos móviles */
@media (min-width: 420px) and (max-width: 599px)  {
  .principalesBlog_cont{
    display: flex;
    flex-direction: column;
}

.articulosSecundarios{
    display: flex;
    flex-direction: column;
}

.articuloSecundario{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.imagenArtSecundario{
  width: 100%;
  max-height: 240px;
  margin-bottom: 10px;
}

.articuloSecundario a{
  display: flex;
  flex-direction: column;
}

.articuloSecundario h3{
  margin-top: 0;
} 

.articuloSecundario p{
  width: 100%;
  font-size: 0.9em;
}

.botonArtSecundario{
  align-self: flex-end;
}
}


@media (max-width: 419px){
  .principalesBlog_cont{
    display: flex;
    flex-direction: column;
}

.articulo_principal{
  height: 400px;
}

.articulosSecundarios{
    display: flex;
    flex-direction: column;
}

.articuloSecundario{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.imagenArtSecundario{
  width: 100%;
  max-height: 240px;
  margin-bottom: 10px;
}

.articuloSecundario a{
  display: flex;
  flex-direction: column;
}

.articuloSecundario h3{
  margin-top: 0;
} 

.articuloSecundario p{
  width: 100%;
  font-size: 0.9em;
}

.botonArtSecundario{
  align-self: flex-end;
}
}