.Dashboard_page{
    background-color: #c6c4c4;
}

.encabezado_DB {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    padding-top: 20px;
    margin: 1%;
    margin-top: 0;
    margin-left: 2.2%;
    background-color: white;
    width: 90%;
    height: 6vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.logoDB{
    width: 120px;
}

.horayFechaDB{
text-align:right ;
}

.horayFechaDB h4{
    margin-bottom: 0;
}

.contenido_DB{
  display: flex;
  flex-direction: row;
}


.principal_DB {
    flex: 1;
    display: flex;
    padding: 20px;
    padding-left: 0;
    width: 60%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    margin-left: 2%;
    height: auto;
    overflow: hidden;
  }

  
  /* Menú de navegación */
  .menu_DB ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #c4c4c429;
    margin-left: 20px;
    height: 64vh;
    padding-top: 30px;
    padding-left: 0;
    margin-right: 30px;
  }
  
  .menu_DB li {
    cursor: pointer;
    padding: 10px;
    padding-left: 20px;
    padding-right: 30px;
  }
  
  .menu_DB li:hover {
    background-color: #f0f0f0;
  }

  .menu_DB ul li.activo {
    background-color: #c5c4c479;
    border-right: 3px solid gray;
    color: rgb(0, 0, 0); 
    font-weight: bold;
  }

  .menu_DB li:nth-child(5){
    margin-top: 90%;
  }

  .menu_DB li:nth-child(5):hover {
    background-color: #c5c4c400;
    color: rgb(0, 0, 0); 
    font-weight: bold;
  }

  .bototCerrarSesion{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    color: rgb(197, 1, 1);
    cursor: pointer;
    border-top: 0.7px solid rgba(128, 128, 128, 0.385);
    width: 100%;
    padding-top: 10px;
  }

  .bototCerrarSesion:hover{
font-weight: 900;
text-decoration: underline;
  }
  
  /* Contenido dinámico */
  .contenido_seccion {
    flex: 1;
  }

  .contenido_seccion h1{
    margin-top: 0;
  }

  .etiqueta{
   font-size: 0.8em;
   width: 80px;
  }

  .PantallaTamañoMal{
    display: none;
  }

  @media (max-width: 1000px) {
     .principal_DB{
display: none;
     }

    .PantallaTamañoMal{
      display: block;
      text-align: center;
    }
  }