.footer{
    display: flex;
    flex-direction: row;
    background-color: #F9D8AB;
    color: #143B36;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    padding-bottom: 5%;
    border-bottom: #143B36 solid 10px;
}

.secc1_footer{
    width: 20%;
    margin-left: 10px;
    margin-right: 10px;
}

.logo_footer{
    width: 70%;
}

.logors_footer{
    width: 30px;
    margin-right: 10px;
}

.separadorFooter{
    border: 0.5px solid #143B36;
}

.secc2_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
}

.secc2_footer h4{
    font-size: 1.3em;
    margin-bottom: 0;
}


.contacto_footer{
    width: 33%;
}

.opcioncontacto_footer{
    display: flex;
    flex-direction: row;
}

.opcioncontacto_footer a{
    text-decoration: none;
}

.iconocontacto_footer{
    width: 20px;
    margin-right: 20px;
}

.texto_contactofooter{
    color: #143B36;
}

.secciones_footer ul,
.informacion_footer ul{
    list-style: none;
    padding-left: 0;
    font-size: 1.1em;
}

.secciones_footer a{
  text-decoration: none;
  color: #143B36;
}

.secciones_footer li{
    margin-bottom: 5px;
}

.secciones_footer a:hover{
    text-decoration:underline;
}

.secciones_footer h4,
.informacion_footer h4{
    margin-bottom: 0;
}

.iconoLlaver{
    width: 20px;
    position: fixed;
    bottom: 0;       
    right: 0;        
    margin: 10px; 
    opacity: 20%;
}

.iconoLlaver:hover{
    opacity: 100%; 
}


/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 768px) {
    .footer{
        display: flex;
        flex-direction: column;
    }

    .secc1_footer{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .secc2_footer{
        width: 100%;
    }

    .logo_footer{
        width: 300px;
        margin-right: 40px;
    }

    .rs_footer{
        display: flex;
        flex-direction: row-reverse;
    }
}

/* Estilos para dispositivos móviles */
@media (min-width: 420px) and (max-width: 599px) {
    .footer{
        display: flex;
        flex-direction: column;
    }

    .secc1_footer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .secc2_footer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .logo_footer{
        width: 300px;
        margin-right: 40px;
    }

    .rs_footer{
        display: flex;
        flex-direction: row-reverse;
    }

    .contacto_footer{
        width: 100%;
    }

    .secciones_footer,
    .informacion_footer{
        width: 100%;
        text-align: center;
    }

}

@media (max-width: 419px){

    

    .footer{
        display: flex;
        flex-direction: column;
    }

    .secc1_footer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .secc2_footer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .logo_footer{
        width: 300px;
        margin-right: 40px;
    }

    .rs_footer{
        display: flex;
        flex-direction: row-reverse;
    }

    .contacto_footer{
        width: 100%;
    }

    .secciones_footer,
    .informacion_footer{
        width: 100%;
        text-align: center;
    }

    .logo_footer{
        width: 200px;
    }
}