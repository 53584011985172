.Habitaciones{
    background-color: #143B36;
    padding-bottom: 60px;
}

.encabezadoHabitaciones{
    background-image: url("../componentes/Habitaciones/portadaHabitaciones.svg");
    background-attachment: fixed;
    background-size: cover; 
  background-position: bottom;
    color: white;
    width: 85%;
    margin-left: 9%;
    height: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
   padding-bottom: 10px;
}

.encabezadoHabitaciones h1{
    margin-top: 60px;
}

.logoHabitaciones{
    width: 400px;
    margin-top: 12%;
}

.separadorRooms{
    width: 80px;
}

.divisionRooms{
    background-color: #143B36;
    padding-bottom: 20px;
    margin-left: 3%;
}

.divisionRooms hr{
    width: 85%;
    background-color: #FAD8A2;
    padding-bottom: 0.3em;
    border: none;
}

.contenedorHabitaciones{
    background-color: white;
    width: 85%;
    margin-left: 9%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  justify-content: center;  /* Centra horizontalmente */
  align-items: center;
}

.HabitacionOpcion{
    background-color: #F8F8F8;
    border: 1px solid #C9C9C9;
    width: 90%;
    margin-bottom: 20px;
    padding-left: 30px;
    display: flex;
    flex-direction: row;            /* Ajusta el alto del contenedor */
  overflow: hidden;
}

.infoRoom{
    width: 90%;
}

.infoRoom h3{
    margin-bottom: 0;
}

.infoRoom h2{
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2em;
}

.contImg_room{
    width: 45%;           /* Ajusta el alto del contenedor */
  overflow: hidden;
}

.imgPrinc_roon{
    width: 100%;
    height: 100%;             /* Hace que la imagen ocupe todo el alto del contenedor */
    object-fit: cover;
}

.infoHabitacionIConos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin-top: 20px;
}

.camas,
.capacidad{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconoRoom{
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.iconoCama{
    width: 38px;
    height: 38px;
}

.textoCapacidad h4,
.textoCapacidad h3{
margin-bottom: 0;
margin-top: 0;
}

.textoCapacidad h4{
    font-weight: 100;
}

.textoCapacidad h3{
    font-size: 1.2em;
}

.botonesRooms{
    margin-top: 12%;
    margin-bottom: 10px;
}

.botonReservar{
    background-color: #61908A;
    color: white;
    font-size: 1.4em;
    border: none;
    padding: 3px 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.botonReservar:hover{
    background-color: #3b766e;
}

.botonInfoRooms{
    border: 0.7px solid #707070;
    background-color:#F8F8F8;
    font-size: 1.3em;
    padding: 3px 19px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 100;
    color: #707070;
}

.botonInfoRooms:hover{
    border: 1px solid #707070;
    color: #444444;
}

/* Estilo general del overlay */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(178, 156, 156, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Contenedor del popup */
  .popupContent {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    border-radius: 8px;
    max-width: 700px;
    padding: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Animación */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Título del popup */
  .popupContent h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333333;
  }
  
  /* Texto dentro del popup */
  .popupContent p {
    font-size: 16px;
    color: #555555;
    margin-bottom: 20px;
  }
  
  /* Estilo del formulario */
  .popupContent form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .popupContent label {
    text-align: left;
    font-size: 14px;
    color: #333333;
  }
  
  .popupContent input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;
  }
  
  /* Botones */
  .popupContent button {
    padding: 10px 15px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .popupContent button:hover {
    background-color: #0056b3;
  }

  .cont_encabezadoReservaciones{
    background-color: #FAD8A2;
    padding: 10px;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .logoFormulario{
    width: 25% !important;
  }

  .separadorFormCheckin{
    display: flex;
    flex-direction: row;
  }

  .pasoCheckin{
    background-color: #143B36;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-left: 30px;
  }

  .pasoCheckin p{
    color: white;
    font-size: 1em;
    font-weight: bold;
  }

  .textoConfirmar {
    margin-top: 0;
    margin-bottom: 0;
  }

  .imagenCheckin{
    width: 90% !important;
  }

  .formularioCheckin{
    padding: 20px;
    width: 50%;
  }

  .fechasCheckin{
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  .infohuespedes_Checkin{
    display: flex;
    flex-direction: row;
    text-align: left;  
    gap: 40px;
}

.infohuespedes_Checkin a{
    display: flex;
    flex-direction: column;
    text-align: left; 
}



.contacto_Checkin{
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 20px; 
}

.paso_Gracias{
  margin-bottom: 50px;
}

.texto_avisoPopUp{
  font-size: 11px !important;
  width: 80%;
} 

.texto_GraciasReserva{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.texto_h2gracias{
  margin-top: 0;
  font-size: 1.5em;
}
  
  /* Botón para cerrar */
  .closePopup {
    background-color: #ffffff66 !important;
    color: rgba(0, 0, 0, 0.099) !important;
    height: 40px;
    margin-top: 10px;
  }

  .contImg_FormGracias2{
width: 75%;
  }

  .texto_popUp3{
    width: 80%;
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
  
  }
  
  
  /* Estilos para tabletas */
  @media (min-width: 601px) and (max-width: 768px) {
    .encabezadoHabitaciones{
      height: 80vh;
      padding-top: 10%;
    }

    .paso_Gracias{
      margin-bottom: 90px;
    }
  }
  
  /* Estilos para dispositivos móviles */
  @media (min-width: 420px) and (max-width: 599px)  {
    .encabezadoHabitaciones{
      height: 65vh;
      padding-top: 20%;
    }

    .logoHabitaciones{
      width: 300px;
      margin-top: 12%;
  }

  .HabitacionOpcion{
    display: flex;
    flex-direction: column-reverse;
    padding-left: 0;
  }

  .contImg_room,
  .imgPrinc_roon{
    width: 100%;
    height: 30vh;
  }

  .infoRoom{
    padding-left: 20px;
  }

  .infoHabitacionIConos{
    width: 90%;
  }

  .iconoRoom{
    width: 20px;
  }

  .textoCapacidad h3{
font-size: 1em;
  }

  .botonesRooms{
    margin-left: 6%;
    width: 90%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  }
  @media (min-width: 420px) and (max-width: 599px){
    .encabezadoHabitaciones{
      height: 65vh;
      padding-top: 20%;
    }

    .logoHabitaciones{
      width: 200px;
      margin-top: 12%;
  }

  .HabitacionOpcion{
    display: flex;
    flex-direction: column-reverse;
    padding-left: 0;
  }

  .contImg_room,
  .imgPrinc_roon{
    width: 100%;
    height: 30vh;
  }

  .infoRoom{
    padding-left: 20px;
  }

  .infoHabitacionIConos{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .capacidad{
    margin-bottom: 10px;
  }

  .infoHabitacionIConos{
    width: 90%;
  }

  .iconoRoom{
    width: 20px;
  }

  .textoCapacidad h3{
font-size: 1em;
  }

  .botonesRooms{
    margin-left: 6%;
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .infoRoom h2{
    font-size: 1.5em;
  }

  .botonInfoRooms{
    width: 100%;
    font-size: 0.5em;
    font-weight: 500;
  }
  }

  @media (max-width: 419px){


    .encabezadoHabitaciones{
      height: 65vh;
      padding-top: 20%;
    }

    .logoHabitaciones{
      width: 200px;
      margin-top: 12%;
  }

  .HabitacionOpcion{
    display: flex;
    flex-direction: column-reverse;
    padding-left: 0;
  }

  .contImg_room,
  .imgPrinc_roon{
    width: 100%;
    height: 30vh;
  }

  .infoRoom{
    padding-left: 20px;
  }

  .infoHabitacionIConos{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .capacidad{
    margin-bottom: 10px;
  }

  .infoHabitacionIConos{
    width: 90%;
  }

  .iconoRoom{
    width: 20px;
  }

  .textoCapacidad h3{
font-size: 1em;
  }

  .botonesRooms{
    margin-left: 6%;
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .infoRoom h2{
    font-size: 1.5em;
  }

  .botonInfoRooms{
    width: 100%;
    font-size: 0.5em;
    font-weight: 500;
  }

  }