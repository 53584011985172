
.pageLogin{
    background-image: url("../componentes/Login/backgound_Login.svg");
    background-position:left center; /* Centra la imagen horizontal y verticalmente */
    background-size: 800px ; /* Hace que la imagen cubra toda el área */
    background-repeat: no-repeat;
    height: 100vh; /* Asegura que el contenedor cubra toda la altura de la ventana */
}

.headerLogin{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
}

.botonRegresarLogin{
    background-color: rgba(240, 248, 255, 0);
    border: 0.5px solid white;
    border-radius: 10px;
    color: white;
    height: 3em;
    padding: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}
.botonRegresarLogin:hover{
    background-color: rgba(255, 255, 255, 0.213);
    color: rgb(255, 255, 255);
    height: 3em;
    padding: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    
}

.flechaReverso{
    transform: scaleX(-1);
    width: 20px;
    margin-right: 20px;
}


.logoLogin{
    width: 200px;
}

.formularioCont{
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0;
    width: 50%;
    margin-left: 25%;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    -webkit-box-shadow: -1px -1px 19px 4px rgba(0,0,0,0.16);
    -moz-box-shadow: -1px -1px 19px 4px rgba(0,0,0,0.16);
    box-shadow: -1px -1px 19px 4px rgba(0,0,0,0.16);

}

.formularioCont h5{
   position: absolute;
   top:140px;
   color: white;
   margin-left: 37px;
   font-size: 2em;
   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.imgLogin{
    width: 100%;
    margin-left: -9%;
}

.formulario{
    width:50% ;
    margin-left: -25%;
    margin-right: 5%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.formulario h2{
    margin-bottom: 0;
}

.formulario p{
    margin-top: 0;
}

.inputLogin{
    height: 2.5em;
    margin-top: 10px;
    border: 1px solid rgb(168, 167, 167);
    border-radius: 5px;
    padding-left: 3px;
    text-align: center;
}

.recuperar_cuenta{
    background-color: white;
    border: none;
    color: rgb(173, 3, 3);
    font-size: 0.7em;
    font-weight: bold;
    margin-left: 50%;
    margin-top: 3px;
    margin-bottom: 10px;
    cursor: pointer
}

.recuperar_cuenta:hover{
    text-decoration: underline;
}

.Loginbutton{
    background-color: #00AFAA;
    border-radius: 50px;
    height: 2em;
    border: none;
    font-size: 1em;
    color: aliceblue;
    cursor: pointer;
    margin-bottom: 10px;
}

.Loginbutton:hover{
    background-color: #019f9a;

}

.mensajeError_texto{
    font-weight: 100;
}

.creditos{
    display: flex;
    flex-direction: row;
    color: gray;
    position: absolute;
    bottom: 0;
    right: 0;
    align-items: center;
    padding-right: 10px;
}

.creditos h5{
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
}

.iconofyf{
    width: 40px;
}