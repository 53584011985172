.capturaCorreoAU_cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3%;
    background-color: #FDCF8F;

}

.CapcorrAU_secc1{
    width: 40%;
    margin-right: 70px;
}

.textfield_CapcorrAU{
    border: none;
    height: 3em;
    width: 180px;
    border-radius:30px 0 0 30px ;
    padding-right: 10px;
}

.boton_CapcorrAU{
    border: none;
    height: 3.1em;
    width: 120px;
    border-radius: 30px;
    margin-left: -30px;
    cursor: pointer;
    color: white;
    background-color: #00AFAA;
    font-weight: 800;
}

.boton_CapcorrAU:hover{
    background-color: #01908c;
}

@media (min-width: 769px) and (max-width: 1024px) {
  
}


/* Estilos para tabletas */
@media (min-width: 601px) and (max-width: 768px) {
    .capturaCorreoAU_cont{
        display: flex;
        flex-direction: column;
    }

    .CapcorrAU_secc1{
        width: 80%;
    }
  
}

/* Estilos para dispositivos móviles */
@media (max-width: 600px) {
    .capturaCorreoAU_cont{
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
    }

    .CapcorrAU_secc1{
        width: 80%;
    }
}
